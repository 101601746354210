
import Vue from "vue";
import { PropType } from "vue";
import { ScytheFaction } from "@/types";

export default Vue.extend({
  name: "scythe-results-table",
  props: {
    factions: {
      type: Array as PropType<ScytheFaction[]>,
      required: true,
    },
    results: {
      type: Array as PropType<{ [key: string]: any }>,
      required: true,
    },
  },
});
