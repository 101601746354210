
import Vue from "vue";
import { mapState } from "vuex";
import { Game, Picture } from "@/types";

export default Vue.extend({
  name: "GameDetail",
  data: () => ({
    loading: true,
    game: {} as Game,
    pictures: [] as Picture[],
    imgError: false,
  }),
  computed: {
    ...mapState(["appUrl", "games"]),
    imgUrl(): string {
      return this.game?.slug ? `${this.appUrl}img/${this.game.slug}.jpg` : "";
    },
  },
  async beforeMount() {
    this.$store
      .dispatch("getGame", { slug: this.$route.params.slug })
      .then(() => this.init());
  },
  methods: {
    init(): void {
      this.game = this.games.find(
        (game: Game) => game.slug === this.$route.params.slug
      );
      this.loading = false;
      this.pictures = [
        {
          src: `${this.appUrl}img/${this.game.slug}_preview.jpg`,
          alt: "game preview",
        },
      ];
    },
  },
});
