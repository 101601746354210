var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{staticClass:"text-center"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Human \\ Automa")]),_vm._l((_vm.factions),function(faction,i){return _c('th',{key:i},[_vm._v(" "+_vm._s(faction.name)+" ")])})],2)]),_c('tbody',_vm._l((_vm.factions),function(factionHuman,i){return _c('tr',{key:i},[_c('th',[_vm._v(_vm._s(factionHuman.name))]),_vm._l((_vm.factions),function(factionAutoma,j){return _c('td',{key:j,class:{
          'bg-red':
            _vm.results[factionAutoma.slug] &&
            _vm.results[factionAutoma.slug][factionHuman.slug] &&
            !_vm.results[factionAutoma.slug][factionHuman.slug].human.winner,
        }},[(i !== j)?[(
              _vm.results[factionAutoma.slug] &&
              _vm.results[factionAutoma.slug][factionHuman.slug]
            )?[_vm._v(" "+_vm._s(_vm.results[factionAutoma.slug][factionHuman.slug].human.score)+" \\ "+_vm._s(_vm.results[factionAutoma.slug][factionHuman.slug].automa.score)+" ")]:[_vm._v(" - ")]]:[_vm._v(" x ")]],2)})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }