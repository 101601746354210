
import Vue from "vue";
import TapestryScoresTable from "@/components/TapestryScoresTable.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "ScoresTapestry",

  components: {
    TapestryScoresTable,
  },

  computed: {
    ...mapState(["tapestryPlays", "tapestryCivilizations"]),
  },
});
