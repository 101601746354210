
import Vue from "vue";
import { mapState } from "vuex";
import { MtgAbility } from "@/types";

export default Vue.extend({
  name: "mtg-abilities",

  data: () => ({
    abilities: [] as MtgAbility[],
    search: "",
  }),

  computed: {
    ...mapState(["appUrl", "mtgAbilities"]),
  },

  async created() {
    this.mtgAbilities.map((ability: MtgAbility) => {
      ability.description = this.insertImages(ability.description);
      if (ability.details && ability.details.length) {
        ability.details.map((detail) => {
          detail.textFr = this.insertImages(detail.textFr);
          return detail;
        });
      }
      return ability;
    });
    this.abilities = this.mtgAbilities;
  },

  watch: {
    search(val: string): void {
      this.abilities = !val
        ? this.mtgAbilities
        : this.mtgAbilities.filter(
            (ability: MtgAbility) =>
              this.normalize(ability.nameFr).indexOf(this.normalize(val)) !==
                -1 ||
              this.normalize(ability.nameEn).indexOf(this.normalize(val)) !== -1
          );
    },
  },

  methods: {
    /**
     * Replace symbols tags by <img>.
     *
     * @param {string} text
     * @return {string}
     */
    insertImages(text: string): string {
      let found = [...text.matchAll(/\{[A-Z0-9]+\}/g)];
      if (!found) {
        return text;
      }
      found.forEach((item) => {
        const img = item[0].slice(1, -1);
        text = text.replaceAll(
          item[0],
          `<img src="${this.appUrl}img/mtg/${img}.png" alt="${img}" />`
        );
      });
      return text;
    },

    /**
     * Normalize a string for search comparison.
     *
     * @param {string} str
     * @return {string}
     */
    normalize(str: string): string {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
  },
});
